<template>
    <div class="wrap-dashboard">
        <deposit-detail-off-temp :dataDetail="dataDetail"></deposit-detail-off-temp>
        <div class="wrap-button">
            <!-- ======= Approve KBI ======= -->
            <div v-show="role == 1 && dataDetail.status == '002'">
                <b-button class="ml-2" variant="success" @click="processDeposit">
                    <span v-show="!loadingDeposit">Process</span>
                    <b-spinner label="Loading..." variant="light" small v-show="loadingDeposit"></b-spinner>
                </b-button>
            </div>
            <div v-show="role == 1 && dataDetail.status == '005' && dataDetail.payment_info.payment_status == 'unpaid' && dataDetail.attachments.invoice_doc != ''">
                <b-button class="ml-2" variant="success" @click="paymentConfirm">
                    <span v-show="!loadingDeposit">Payment Confirmation</span>
                    <b-spinner label="Loading..." variant="light" small v-show="loadingDeposit"></b-spinner>
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
// import pdf from "vue-pdf";
import { required } from "vuelidate/lib/validators";
import DepositDetailOffTemp from "../../../../components/deposit/DepositDetailOffTemp.vue";

export default {
    components: {
        DepositDetailOffTemp,
    },
    data() {
        return {
            loadingOperatorConfirm: false,
            loadingDeposit: false,
            loadingKbi: false,
            loadingMetal: false,
            dataDetail: {},
            dataHolding: [],
            dataNasabah: [],
            pageOfItems: [],
            isImage: true,
            currentPage: 1,
            perPage: 10,
            lengthData: 1,
            trade_id: [],
            metal_id: "",
            withdrawal_id: "",
            status_operator: "",
            offset: 0,
            deposit_status: "",
            deposit_type: "",
            label_custom: "",
            label_invoice: "",
            label_manufacturing: "",
            label_vault: "",
            label_clearing: "",
            label_bpbatam: "",
            label_cukai: "",
            loadDataCustomDoc: false,
            loadDataInvoice: false,
            loadDataManu: false,
            loadDataVault: false,
            loadDataClearing: false,
            loadDataBpbatam: false,
            loadDataCukai: false,
            isLoadingPreview: false,
            statusDeposit: "",
            role: "",
            dataImage: [],
            numPages: undefined,
            src: null,
            isPdf: false,
            labelPreview: "",
            form: {
                allocated: "",
                hub: "",
                dimension: "",
                fineness: "",
                hallmark: "",
                serial_number: "",
                year: "",
            },
            form_validation: {
                allocated: null,
                hub: "",
                dimension: "",
                fineness: "",
                hallmark: "",
                serial_number: "",
                year: "",
            },
            form_attachment: {
                metal_id: "",
                customs_doc: "",
                commercial_invoice: "",
                manufacturing_doc: "",
                vault_doc: "",
                clearing_doc: "",
                recommendation_doc: "",
                ftz_doc: "",
                recommendation_doc_no: "",
                ftz_doc_no: "",
            },
        };
    },
    validations: {
        form_attachment: {
            customs_doc: {
                required,
            },
            commercial_invoice: {
                required,
            },
            manufacturing_doc: {
                required,
            },
            vault_doc: {
                required,
            },
            clearing_doc: {
                required,
            },
            recommendation_doc: {
                required,
            },
            ftz_doc: {
                required,
            },
            recommendation_doc_no: {
                required,
            },
            ftz_doc_no: {
                required,
            },
        },
    },
    mounted() {
        // this.getData(this.currentPage);
        this.withdrawal_id = this.$route.params.id;
        this.role = sessionStorage.getItem("role");
        let url;
        if (this.role == 5) {
            url = "/v2/clearing/operator/get/detail/deposit/" + this.withdrawal_id;
        } else if (this.role == 2) {
            url = "/v2/clearing/admin/abx/get/detail/deposit/" + this.withdrawal_id;
        } else if (this.role == 1) {
            url = "/v2/clearing/admin/get/detail/deposit/" + this.withdrawal_id;
        }
        axios.get(url).then((res) => {
            this.dataDetail = res.data.data.body;
            this.dataHolding = res.data.data.holdings;
            this.dataNasabah = res.data.data.nasabah;
            this.deposit_status = res.data.data.body.status;
            this.deposit_type = res.data.data.body.type;

            for (let i = 0; i < this.dataDetail.length; i++) {
                this.form.allocated = this.dataDetail[i].allocated;
                this.form.hub = this.dataDetail[i].hub;
                this.form.dimension = this.dataDetail[i].dimension;
                this.form.fineness = this.dataDetail[i].fineness;
                this.form.hallmark = this.dataDetail[i].hallmark;
                this.form.serial_number = this.dataDetail[i].serial_number;
                this.form.year = this.dataDetail[i].year;
            }
        });
    },
    methods: {
        openPreview(file, label) {
            this.$refs["preview-modal"].show();
            this.labelPreview = label;
            this.isLoadingPreview = true;
            let newFile = file.replace("/kmi", "/vms");
            this.getImage(newFile)
                .then((res) => {
                    if (file.split(".").pop() == "pdf") {
                        this.isPdf = true;
                        this.isLoadingPreview = false;
                        // this.src = pdf.createLoadingTask(res);
                        // this.src.promise.then((pdf) => {
                        //   this.numPages = pdf.numPages;
                        // });
                    } else {
                        this.isLoadingPreview = false;
                        this.isPdf = false;
                        this.src = res;
                    }
                })
                .catch(() => {
                    this.isLoadingPreview = false;
                });
        },
        async getImage(file) {
            let image = await axios.get("/v2" + file, { responseType: "arraybuffer" });
            image = URL.createObjectURL(new Blob([image.data], { type: image.data.type }));
            return image;
        },
        handlePageChange(value) {
            this.getData(value);
            window.scrollTo({ top: 0, behavior: "smooth" });
        },
        filterDate() {},
        getData(value) {
            let offset = (value - 1) * this.perPage;
            console.log(offset);
        },
        showModalOperator(id, status) {
            this.status_operator = status;
            this.metal_id = id;
            this.$refs["modal-operator"].show();
        },

        hideModalOperator() {
            this.$refs["modal-operator"].hide();
        },
        showModal(id) {
            this.metal_id = id;
            this.$refs["my-modal"].show();
        },
        hideModal() {
            this.$refs["my-modal"].hide();
        },

        operatorKonfirmation() {
            this.loadingOperatorConfirm = true;
            let value;
            if (this.status_operator == 1) {
                value = "accept";
            } else {
                value = "reject";
            }
            // console.log(value);
            axios
                .put("/v1/clearing/operator/metal/confirmation/" + this.metal_id, { option: value })
                .then((res) => {
                    if (res.status == 200) {
                        this.loadingOperatorConfirm = false;
                        this.$toast.success(res.data.message, {
                            position: "top-center",
                            timeout: 3000,
                            closeOnClick: true,
                            pauseOnFocusLoss: false,
                            pauseOnHover: false,
                            showCloseButtonOnHover: false,
                            hideProgressBar: false,
                            closeButton: "button",
                            icon: true,
                            rtl: false,
                        });
                        setTimeout(function () {
                            location.reload();
                        }, 3000);
                    }
                })
                .catch((err) => {
                    this.loadingOperatorConfirm = false;
                    this.$toast.error(err.response.data.message, {
                        position: "top-center",
                        timeout: 3000,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: false,
                        showCloseButtonOnHover: false,
                        hideProgressBar: false,
                        closeButton: "button",
                        icon: true,
                        rtl: false,
                    });
                });
        },
        submitMetal() {
            this.loadingMetal = true;
            axios
                .put("/v1/clearing/admin/abx/metal/" + this.metal_id, this.form)
                .then((res) => {
                    if (res.status == 200) {
                        this.loadingMetal = false;
                        this.$toast.success(res.data.message, {
                            position: "top-center",
                            timeout: 3000,
                            closeOnClick: true,
                            pauseOnFocusLoss: false,
                            pauseOnHover: false,
                            showCloseButtonOnHover: false,
                            hideProgressBar: false,
                            closeButton: "button",
                            icon: true,
                            rtl: false,
                        });
                        setTimeout(function () {
                            location.reload();
                        }, 3000);
                    }
                })
                .catch((error) => {
                    this.loadingMetal = false;
                    this.$toast.error(error.response.data.message, {
                        position: "top-center",
                        timeout: 3000,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: false,
                        showCloseButtonOnHover: false,
                        hideProgressBar: false,
                        closeButton: "button",
                        icon: true,
                        rtl: false,
                    });
                });
        },
        paymentConfirm() {
            if (this.dataDetail.attachments.receipt_doc) {
                this.loadingDeposit = true;
                this.$store.dispatch("getLocation").then((resGeo) => {
                    let info;
                    if (resGeo.cityData[0]) {
                        info = JSON.stringify(resGeo);
                    } else {
                        let data = resGeo;
                        data.newLatLong = resGeo.cityLatLong.split(",");
                        info = JSON.stringify(data);
                    }
                    let data = {
                        _id: this.withdrawal_id,
                        info: info,
                    };
                    axios.put("/v2/clearing/admin/offexchange/deposit/payment_confirmation", data).then((res) => {
                        if (!res.data.iserror) {
                            this.$toast.success("Confirmation Payment Succesfully Processed");
                            setTimeout(function () {
                                location.reload();
                            }, 3000);
                        }
                    });
                });
            } else {
                this.$toast.error("Document Proof Payment is Required");
            }
        },
        processDeposit() {
            this.loadingDeposit = true;
            let url;
            if (this.deposit_type == "onexchange") {
                if (this.role == 2) {
                    url = "/v2/clearing/admin/abx/deposit/confirmation";
                    this.hitUrlDeposit(url);
                } else if (this.role == 5) {
                    this.$v.form_attachment.$touch();
                    if (this.$v.form_attachment.$error) {
                        this.loadingDeposit = false;
                        return;
                    }
                    this.form_attachment.metal_id = this.withdrawal_id;
                    url = "/v2/clearing/operator/deposit/confirmation";
                    axios.post("/v2/clearing/operator/update_attachment", this.form_attachment).then((res) => {
                        if (res.data.data.status_code == "00") {
                            this.hitUrlDeposit(url);
                        }
                    });
                } else {
                    url = "/v2/clearing/admin/deposit/confirmation";
                    this.hitUrlDeposit(url);
                }
            } else {
                if (this.role == 1) {
                    this.statusDeposit = "003";
                    url = "/v2/clearing/admin/offexchange/deposit/update";
                    this.hitUrlDeposit(url);
                }
            }
        },
        hitUrlDeposit(url) {
            this.$store.dispatch("getLocation").then((resGeo) => {
                let info;
                if (resGeo.cityData[0]) {
                    info = JSON.stringify(resGeo);
                } else {
                    let data = resGeo;
                    data.newLatLong = resGeo.cityLatLong.split(",");
                    info = JSON.stringify(data);
                }
                let data;

                if (this.deposit_type == "onexchange") {
                    data = {
                        _id: this.withdrawal_id,
                        info: info,
                    };
                } else {
                    data = {
                        _id: this.withdrawal_id,
                        status: this.statusDeposit,
                        info: info,
                    };
                }
                // console.log(data, url);
                axios
                    .put(url, data)
                    .then((res) => {
                        if (res.status == 200) {
                            this.loadingDeposit = false;
                            this.$toast.success("The deposit has been successfully processed", {
                                position: "top-center",
                                timeout: 3000,
                                closeOnClick: true,
                                pauseOnFocusLoss: false,
                                pauseOnHover: false,
                                showCloseButtonOnHover: false,
                                hideProgressBar: false,
                                closeButton: "button",
                                icon: true,
                                rtl: false,
                            });
                            setTimeout(function () {
                                location.reload();
                            }, 3000);
                        }
                    })
                    .catch((err) => {
                        this.loadingDeposit = false;
                        this.$toast.error(err.response.data.message, {
                            position: "top-center",
                            timeout: 3000,
                            closeOnClick: true,
                            pauseOnFocusLoss: false,
                            pauseOnHover: false,
                            showCloseButtonOnHover: false,
                            hideProgressBar: false,
                            closeButton: "button",
                            icon: true,
                            rtl: false,
                        });
                    });
            });
        },
        apprveKBI() {
            this.loadingKbi = true;
            axios
                .put("/v1/clearing/admin/deposit/confirmation" + this.withdrawal_id)
                .then((res) => {
                    if (res.status == 200) {
                        this.loadingKbi = false;
                        this.$toast.success(res.data.message, {
                            position: "top-center",
                            timeout: 3000,
                            closeOnClick: true,
                            pauseOnFocusLoss: false,
                            pauseOnHover: false,
                            showCloseButtonOnHover: false,
                            hideProgressBar: false,
                            closeButton: "button",
                            icon: true,
                            rtl: false,
                        });
                        setTimeout(function () {
                            location.reload();
                        }, 3000);
                    }
                })
                .catch((err) => {
                    this.loadingKbi = false;
                    this.$toast.error(err.response.data.message, {
                        position: "top-center",
                        timeout: 3000,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: false,
                        showCloseButtonOnHover: false,
                        hideProgressBar: false,
                        closeButton: "button",
                        icon: true,
                        rtl: false,
                    });
                });
        },
        // onFileChange(formInput, label, ref, loadData) {
        //   console.log(formInput, label, ref, loadData);
        //   this[loadData] = true;
        //   const fileInput = this.$refs[ref].files[0];
        //   if (fileInput.type == "image/jpeg" || fileInput.type == "image/jpg" || fileInput.type == "image/png" || fileInput.type == "application/pdf") {
        //     this.requestFormData(fileInput).then((res) => {
        //       this[loadData] = false;
        //       this[label] = fileInput.name;
        //       this[formInput] = res.data.data;
        //       console.log(this.form_attachment);
        //     });
        //     return this[loadData], this[label], this[formInput];
        //   }
        // },
        onCustomDoc() {
            this.loadDataCustomDoc = true;
            const fileCustomDoc = this.$refs.customsDoc.files[0];
            if (
                fileCustomDoc.type == "image/jpeg" ||
                fileCustomDoc.type == "image/jpg" ||
                fileCustomDoc.type == "image/png" ||
                fileCustomDoc.type == "application/pdf"
            ) {
                this.requestFormData(fileCustomDoc).then((res) => {
                    this.loadDataCustomDoc = false;
                    this.label_custom = fileCustomDoc.name;
                    this.form_attachment.customs_doc = res.data.data;
                });
            }
        },
        onInvoice() {
            const fileInvoice = this.$refs.invoice.files[0];
            this.loadDataInvoice = true;
            if (fileInvoice.type == "image/jpeg" || fileInvoice.type == "image/jpg" || fileInvoice.type == "image/png" || fileInvoice.type == "application/pdf") {
                this.requestFormData(fileInvoice).then((res) => {
                    this.loadDataInvoice = false;
                    this.label_invoice = fileInvoice.name;
                    this.form_attachment.commercial_invoice = res.data.data;
                });
            }
        },
        onManfacturing() {
            this.loadDataManu = true;
            const fileManuFacturing = this.$refs.manufactur.files[0];
            if (
                fileManuFacturing.type == "image/jpeg" ||
                fileManuFacturing.type == "image/jpg" ||
                fileManuFacturing.type == "image/png" ||
                fileManuFacturing.type == "application/pdf"
            ) {
                this.requestFormData(fileManuFacturing).then((res) => {
                    this.loadDataManu = false;
                    this.label_manufacturing = fileManuFacturing.name;
                    this.form_attachment.manufacturing_doc = res.data.data;
                });
            }
        },
        onVault() {
            this.loadDataVault = true;
            const fileVault = this.$refs.vault.files[0];
            if (fileVault.type == "image/jpeg" || fileVault.type == "image/jpg" || fileVault.type == "image/png" || fileVault.type == "application/pdf") {
                this.requestFormData(fileVault).then((res) => {
                    this.loadDataVault = false;
                    this.label_vault = fileVault.name;
                    this.form_attachment.vault_doc = res.data.data;
                });
            }
        },
        onClearing() {
            this.loadDataClearing = true;
            const fileClearing = this.$refs.clearing.files[0];
            if (
                fileClearing.type == "image/jpeg" ||
                fileClearing.type == "image/jpg" ||
                fileClearing.type == "image/png" ||
                fileClearing.type == "application/pdf"
            ) {
                this.requestFormData(fileClearing).then((res) => {
                    this.loadDataClearing = false;
                    this.label_clearing = fileClearing.name;
                    this.form_attachment.clearing_doc = res.data.data;
                });
            }
        },
        onBpBatam() {
            this.loadDataBpbatam = true;
            const fileBpBatam = this.$refs.bpBatam.files[0];
            if (fileBpBatam.type == "image/jpeg" || fileBpBatam.type == "image/jpg" || fileBpBatam.type == "image/png" || fileBpBatam.type == "application/pdf") {
                this.requestFormData(fileBpBatam).then((res) => {
                    this.loadDataBpbatam = false;
                    this.label_bpbatam = fileBpBatam.name;
                    this.form_attachment.recommendation_doc = res.data.data;
                });
            }
        },
        onBeaCukai() {
            this.loadDataCukai = true;
            const fileFtz = this.$refs.beaCukai.files[0];
            if (fileFtz.type == "image/jpeg" || fileFtz.type == "image/jpg" || fileFtz.type == "image/png" || fileFtz.type == "application/pdf") {
                this.requestFormData(fileFtz).then((res) => {
                    this.loadDataCukai = false;
                    this.label_cukai = fileFtz.name;
                    this.form_attachment.ftz_doc = res.data.data;
                });
            }
        },
        requestFormData(fileData) {
            let formData = new FormData();
            formData.append("file", fileData);
            return axios.post("/v2/upload", formData);
        },
    },
    computed: {
        rows() {
            return (this.currentPage - 1) * this.perPage;
        },
        depositDetail() {
            return this.dataDetail;
        },
        detailItemMetal() {
            return this.dataDetail.deposit_detail;
        },
    },
};
</script>

<style scoped>
/*.wrap-dashboard {
  min-height: 100vh;
  margin: 0;
  display: grid;
  grid-template-rows: auto 1fr auto;
}*/

.error {
    font-size: 12px;
    color: #f5365c;
}

.upload-video {
    border: 1px solid #1f354a;
    padding: 0.5rem;
    color: #1f354a;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
    font-weight: bold;
}

.upload-video:hover {
    background: #1f354a;
    color: #ffffff;
}

.icon-upload {
    font-size: 13px;
}

#inputTag {
    display: none;
}

input[type="file"] {
    display: none;
}

.form-input {
    width: 50%;
    border-radius: 5px;
    border: none;
    padding: 0.5rem 1rem;
    box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
}

.form-input:focus {
    border-color: var(--primary-color);
    outline: 0;
    box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
}

.content-data {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.content-data span:first-child {
    width: 115px;
    font-weight: bold;
}

.wrap-left,
.wrap-right {
    width: 49%;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 11%);
    padding: 1.5rem;
    border-radius: 5px;
}

.fa-check {
    color: #28a745;
    cursor: pointer;
}
.fa-times {
    color: #dc3545;
    cursor: pointer;
}

.title-page {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
}

.download-deposit {
    font-size: 15px;
    font-weight: normal;
}

.form-group {
    margin-bottom: 1.7rem;
}

.label-form {
    display: block;
    width: 100%;
    font-weight: bold;
}

input {
    font-size: 13px;
}

.buuton-add {
    text-align: right;
    font-size: 13px;
}

/* .wrap-content {
  width: 98%;
  height: 100%;
  margin: 0 auto;
  padding: 20px 25px;
} */

.content {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-top: 15px;
}

.icon-trade {
    width: 25px;
}

.footer {
    background: var(--primary-color);
    color: white;
    padding: 20px 47px;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    height: 62px;
}

.filter {
    display: table-cell;
    width: 20%;
    font-size: 12px;
}
.wrap-input {
    display: flex;
}

select {
    width: 130px;
    border-radius: 3px;
    border-color: #a5a5a5a5;
    padding: 3px;
}

.card {
    width: 314px;
    font-size: 14px;
    border-radius: 10px;
}

.card-header {
    padding: 1rem;
}

.buyer,
.seller,
.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    font-size: 12px;
    position: relative;
    left: 20px;
}

.title::before {
    content: "";
    position: absolute;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: var(--scondary-color);
    left: -17px;
    top: 3px;
}

.buyer {
    margin-bottom: 20px;
}

.content-right {
    text-align: right;
}

.amount {
    font-weight: bold;
}

.btn-primer {
    background: var(--scondary-color);
    border: none;
    color: #ffffff;
    padding: 5px 25px;
    border-radius: 5px;
}

.btn-primer:hover {
    background-color: var(--scondary-color-hover);
}

.status-allocated {
    font-weight: 600;
    color: #2dce89;
}

.status-processed {
    font-weight: 600;
    color: rgb(172, 143, 26);
}

.status-rejected {
    font-weight: 600;
    color: #f5365c;
}
</style>
